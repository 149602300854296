import store from 'store2';
import * as _ from 'lodash';
import axios from 'axios';
import JWTDecode from 'jwt-decode';
import { logOut, refreshAccessToken } from '../actions/actionsAuth';
import reduxStore from '../configureStore';
import replaceEndpoints from '../replaceEndpoints';

let fails: number = 0;

export const isLoggedIn = () => {
  const { userReducer } = reduxStore.getState();
  const accessToken = _.get(userReducer, 'data.accessToken', false);
  console.log(`Access token: ${accessToken}`);
  return accessToken;
};

export const setupInterceptors = () => {
  const updateToken = accessToken => {
    store.transact('swsmUser', obj => {
      obj.data.accessToken = accessToken;
    });
  };

  let isFetchingToken = false;
  let tokenSubscribers: any = [];

  function subscribeTokenRefresh(cb) {
    tokenSubscribers.push(cb);
  }

  function onTokenRefreshed(errRefreshing, token) {
    tokenSubscribers.map(cb => cb(errRefreshing, token));
  }

  function forceLogout() {
    isFetchingToken = false;
    reduxStore.dispatch<any>(logOut());
  }

  axios.interceptors.request.use(
    reqConfig => {
      const provideRefresh = _.includes(reqConfig.url, '/logout') || _.includes(reqConfig.url, '/token');
      const swsmAPI =
        _.includes(reqConfig.url, 'savewatersavemoney.co.uk') ||
        _.includes(reqConfig.url, 'getwaterfit.co.uk') ||
        _.includes(reqConfig.url, 'smartwateradvice.getwaterfit.com') ||
        _.includes(reqConfig.url, 'a4we.getwaterfit.com');

      if (!swsmAPI) {
        return reqConfig; //make sure we only add tokens to SWSM API's
      }

      const injectRedirections = replaceEndpoints(reqConfig);
      reqConfig = injectRedirections;

      // const { userReducer } = reduxStore.getState();

      const accessToken = _.get(store('swsmUser'), 'data.accessToken');
      const refreshToken = _.get(store('swsmUser'), 'data.refreshToken');

      reqConfig.headers.authorization = 'Bearer ' + accessToken;

      if (provideRefresh) {
        reqConfig.headers['x-refresh-token'] = refreshToken;
      }

      return reqConfig;
    },
    err => Promise.reject(err),
  );

  axios.interceptors.response.use(
    res => {
      if (!_.includes(res.config.url, '/token')) {
        fails = 0;
      }
      return res;
    },
    err => {
      if (err.response === undefined) {
        return Promise.reject(err);
      }
      else if (_.includes(err.response.config.url, '/login') || _.includes(err.response.config.url, '/reset')) {
        return Promise.reject(_.get(err, 'response.data.message', 'LogIn'));
      }
      else if (err.response.status === 403) {
        // The request is for something forbidden. Authorization will not help.
        forceLogout();

        return Promise.reject('Authorization will not help');
      }
      else if (err.response.status === 404) {
        return Promise.reject(err.response);
      }
      else if (err.response.status !== 401) {
        return Promise.reject(err.response); //only 401 unauthorized
      }

      fails += 1;
      if (fails >= 3) {
        console.log(`${fails} unsuccessfull token refreshes in row.`);
        forceLogout();
        return Promise.reject(err.response);
      }

      if (!isFetchingToken) {
        const refreshToken = _.get(store('swsmUser'), 'data.refreshToken', false);

        isFetchingToken = true;

        if (!refreshToken) {
          // console.log('No token found in localStorage');
          forceLogout();
          return Promise.reject(err.response);
        }
        try {
          const isRefreshTokenExpired = JWTDecode(refreshToken).exp < Date.now() / 1000;

          if (isRefreshTokenExpired) {
            // console.log('isRefreshTokenExpired');
            forceLogout();
            return Promise.reject(err.response);
          }
        }
        catch (error) {
          // console.log('Can\'t decode token');
          forceLogout();
          return Promise.reject(err.response);
        }

        reduxStore
          .dispatch<any>(refreshAccessToken())
          .then(res => {
            isFetchingToken = false;
            const newAccessToken = res.value;
            if (newAccessToken === null) {
              forceLogout();
            }
            else {
              onTokenRefreshed(null, newAccessToken);
              tokenSubscribers = [];
              updateToken(newAccessToken);
            }
          })
          .catch(err => {
            // console.log('refresh token issue CATCH');
            onTokenRefreshed(Promise.reject(_.get(err, 'data.message', 'Unable to refresh token')), null);
            tokenSubscribers = [];
            forceLogout();
          });
      }

      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((errRefreshing, newToken) => {
          if (errRefreshing) {
            // console.log('errRefreshing');
            return reject(errRefreshing);
          }
          // console.log('resolve again');
          err.config.headers.authorization = newToken;
          return resolve(axios(err.config));
        });
      });
    },
  );
};
