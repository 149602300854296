/* eslint-disable no-nested-ternary */
import React from 'react';
import Styled from './UnifiedLabel.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import { Icon } from '@savewatersavemoney/swsm-ui';
import Typography from '../Typography';
import history from '../../history';
import { getVariable } from '../../helpers';
import { useTranslation } from 'react-i18next';
interface UnifiedLabelProps {
  data: any;
  isDesktop: boolean;
}

const UnifiedLabel: React.FC<UnifiedLabelProps> = props => {
  const { data, isDesktop } = props;
  const country = getVariable('client', 'waterClient', 'aus');
  const nationalData = _.get(data, 'data.nationalWaterUsePerPerson');
  const userData = _.get(data, 'data.userWaterUsePerPerson');
  const [t] = useTranslation('common');
  const renderNationalData = country === 'usa' ? (nationalData * 365) / 12 : nationalData;
  const renderUserData = country === 'usa' ? (userData * 365) / 12 : userData;

  const labels = [
    { grade: 140, color: '#4CAF50' }, // Green
    { grade: 200, color: '#CDDC39' }, // Lime
    { grade: 260, color: '#FFC107' }, // Amber
    { grade: 320, color: '#FF9800' }, // Orange
    { grade: 380, color: '#F44336' }, // Red
  ];

  const labelsUsa = [
    { grade: 1250, color: '#4CAF50' }, // Green
    { grade: 2500, color: '#CDDC39' }, // Lime
    { grade: 3250, color: '#FFC107' }, // Amber
    { grade: 4000, color: '#FF9800' }, // Orange
    { grade: 5000, color: '#F44336' }, // Red
  ];

  const renderLabel = country === 'usa' ? labelsUsa : labels;

  const width = [35, 52, 72, 85, 100];

  const lineValueUse =
    ((renderNationalData - renderLabel[0].grade) * 200) / (renderLabel[4].grade - renderLabel[0].grade);

  const barValueUse = ((renderUserData - renderLabel[0].grade) * 177) / (renderLabel[4].grade - renderLabel[0].grade);

  const colorChange = () => {
    if (renderUserData < renderLabel[1].grade - 1) {
      return '#4CAF50';
    }
    else if (renderUserData < renderLabel[2].grade - 1 && renderUserData > renderLabel[1].grade) {
      return '#CDDC39';
    }
    else if (renderUserData < renderLabel[3].grade - 1 && renderUserData > renderLabel[2].grade) {
      return '#FFC107';
    }
    else if (renderUserData < renderLabel[4].grade - 1 && renderUserData > renderLabel[3].grade) {
      return '#FF9800';
    }
    else if (renderUserData > renderLabel[4].grade) {
      return '#F44336';
    }
  };

  const title = t('page.overview.unified-label.title');
  const subTitle = t('page.overview.unified-label.subtitle');
  const nationalAvg = t('page.overview.unified-label.national-avg');
  const you = t('page.overview.unified-label.you');

  return (
    <Row>
      <Col md={4} xs={12} sm={12}>
        <Styled.Wrap>
          <Styled.TextContainer onClick={() => history.push('/myuse')}>
            <Icon icon="waterCoins" size={50} />
            <div style={{ margin: '0px 12px' }}>
              <Typography size="4" text={title} style={{ marginBottom: '0px', textDecoration: 'underline' }} />
              <Typography size="p" text={subTitle} style={{ fontSize: '14px' }} />
            </div>
          </Styled.TextContainer>
          <div style={{ position: 'relative' }}>
            <Styled.NationalLine
              style={{
                top: `${lineValueUse}px`,
              }}
            >
              <p>{nationalAvg}</p>
              <hr />
            </Styled.NationalLine>

            <Row>
              <Col md={6} xs={6} sm={6}>
                <Styled.EnergyLabelContainer>
                  {renderLabel.map((label, index) => (
                    <Styled.EnergyLabelBar key={label.grade} color={label.color} width={width[index]}>
                      <Styled.EnergyLabelGrade>
                        {label.grade}
                        {country === 'usa' ? 'gal' : 'L'}
                      </Styled.EnergyLabelGrade>
                    </Styled.EnergyLabelBar>
                  ))}
                </Styled.EnergyLabelContainer>
              </Col>
              <Col md={6} xs={6} sm={6}>
                <Styled.MeContainer>
                  <Styled.MeLabelBar
                    color={colorChange()}
                    width={65}
                    style={{
                      marginTop:
                        renderUserData > renderLabel[4].grade
                          ? '177px'
                          : renderUserData < renderLabel[0].grade
                            ? '0px'
                            : barValueUse,
                    }}
                  >
                    <Styled.MeLabelGrade>
                      {you}: {renderUserData && renderUserData.toFixed(0)}
                      {country === 'usa' ? 'gal' : 'L'}
                    </Styled.MeLabelGrade>
                  </Styled.MeLabelBar>
                </Styled.MeContainer>
              </Col>
            </Row>
          </div>
        </Styled.Wrap>
      </Col>
      <Col md={8} sm={12} xs={12}>
        <Styled.BannerWrap isDesktop={isDesktop}>
          <Styled.BannerImage />
          <Styled.BannerCopy>
            <Typography size="3" text="Tap off, tune in!" />
            <Typography
              size="p"
              text="Turn off your taps on 24 October 5pm-10pm and join Australia in improving water knowledge."
            />
            <Styled.BannerButton href="https://waternight.com.au/" target="_blank" rel="noopener noreferrer">
              Register now
            </Styled.BannerButton>
          </Styled.BannerCopy>
        </Styled.BannerWrap>
      </Col>
    </Row>
  );
};
export default UnifiedLabel;
