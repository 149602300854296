import { combineReducers } from 'redux';
import authReducer from './auth';
import appReducer from './app';
import uiReducer from './ui';
import externalLinksReducer from './externalLinks';
import userReducer from './user';
import locationReducer from './location';
import challengesReducer from './challenges';
import pageOverviewReducer from './pageOverview';
import pageConsumptionReducer from './pageConsumption';
import preferencesReducer from './preferences';
import pageFreeProductsReducer from './pageFreeProducts';
import pageProductsFittedReducer from './pageProductsFitted';
import pageActivityHistoryReducer from './pageActivityHistory';
import pageLeaderboardReducer from './pageLeaderboard';
import pageCompetitionsReducer from './pageCompetitions';
import pageUnsubscribeReducer from './unsubscribe';
import actionBannerReducer from './banner';
import discountLinkReducer from './discountLink';
import getInfoReducer from './getInfo';
import savingengine from './savingengine';
import { connectRouter } from 'connected-react-router';
import history from '../history';
import store from 'store2';
import modal from './modal';
import supportTicketReducer from './supportTicket';
import translationReducer from './translation';
import filterReducer from './filter';
import videoLibraryReducer from './videoLibrary';
import notificationsReducer from './notifications';
import availableRewardsReducer from './availableRewards';
import optRewardsReducer from './optRewards';
import promoCodeReducer from './promoCode';
import deleteAccountReducer from './delete';
import contactFormReducer from './contactForm';
import marketplaceReducer from './marketplace';
import searchReducer from './search';
import companyNameReducer from './companyName';
import adAnalyticsReducer from './adAnalytics';
import suggestiveSearchReducer from './suggestiveSearch';
import reportingFiguresReducer from './reportingFIgures';
import unifiedLabelReducer from './unifiedLabel';

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_USERDATA') {
    store((key, data) => {
      if (key !== 'swsmCookiePolicy') {
        store.remove(key);
      }
    });
    state = {
      router: {
        ...state.router,
      },
      uiReducer: {
        ...state.uiReducer,
      },
      userReducer: undefined,
    };
  }
  return mainReducer(state, action);
};

const mainReducer = combineReducers({
  supportTicketReducer,
  pageFreeProductsReducer,
  authReducer,
  externalLinksReducer,
  appReducer,
  challengesReducer,
  locationReducer,
  uiReducer,
  preferencesReducer,
  userReducer,
  pageOverviewReducer,
  pageConsumptionReducer,
  pageActivityHistoryReducer,
  pageLeaderboardReducer,
  pageCompetitionsReducer,
  pageUnsubscribeReducer,
  pageProductsFittedReducer,
  actionBannerReducer,
  discountLinkReducer,
  getInfoReducer,
  translationReducer,
  videoLibraryReducer,
  notificationsReducer,
  router: connectRouter(history),
  savingengineReducer: savingengine,
  modal,
  filterReducer,
  availableRewardsReducer,
  optRewardsReducer,
  promoCodeReducer,
  deleteAccountReducer,
  contactFormReducer,
  marketplaceReducer,
  searchReducer,
  companyNameReducer,
  adAnalyticsReducer,
  suggestiveSearchReducer,
  reportingFiguresReducer,
  unifiedLabelReducer,
});

export default rootReducer;
